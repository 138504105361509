body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #root {
  min-height: 100vh;
}

#root  {
  display: flex;
  flex-direction: column
}

.flexVerticalContainer {
  display: flex;
  flex-direction: column
}

.flexHorizontalContiner {
  display: flex;
  flex-direction: row
}

.flexCentered {
  justify-content: center;
  vertical-align: center;
}

.verticalGrower {
  flex-grow: 1
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn {
  margin: 0.1em;
}

.spinnerselect {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinnerwrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.2em;
}

.formUnit {
  margin-top: 1em;
  margin-bottom: 1em;
}

.codeServerIframe {
  width: 100%;
  display: block;
}

.tab-content > .tab-pane {
  display:none;
}

.tab-content, .tab-content > .active {
  display:contents;
}

.layeredContainer {
  display:grid
}

.layeredBackground {
  position: relative;
  grid-column-start: 1;
  grid-row-start: 1;
  z-index: -1;
}

.layeredForeground {
  position: relative;
  grid-column-start: 1;
  grid-row-start: 1;
  z-index: 1;
}